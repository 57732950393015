$color-default: #4d4d4d;
$color-light: #fff;
$color-purple: #7b59a2;


$font-default: "Noto sans JP",
sans-serif;
$font-poppins: "Poppins",
sans-serif;
$font-mont: "Montserrat",
sans-serif;