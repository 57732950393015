@import "./Foundation/variables";
@import "./Foundation/mediaqueries";
@import "./Foundation/fontsize";

// @font-face {
//   font-family: 'SangBleu Sunrise';
//   src: url('../font/SangBleuSunrise-Medium.ttf');
// }

*,
body {
  font-family: $font-default;
  letter-spacing: 0.1em;
  color: $color-default;
  font-weight: 500;

  @include pc {
    letter-spacing: 0.14em;
  }
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
  font-feature-settings: "palt";

  @include pc {
    scroll-padding-top: 130px;
  }
}

body {
  color: $color-light;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td,
span,
a {
  line-height: 1.6;
}

img {
  width: 100%;
  vertical-align: bottom;
}

a {
  color: $color-default;
  transition: all 0.4s;
  // &:hover {
  //   opacity: 0.7;
  // }
}

.sp {
  display: block !important;

  @include pc() {
    display: none !important;
  }
}

.pc {
  display: none !important;

  @include pc() {
    display: block !important;
  }
}

.container {
  overflow: hidden;
}


main {
  margin-top: 74px;

  @include pc {
    margin-top: 0;
  }
}

.c-btn {
  width: 205px;

  @include pc {
    width: 250px;
  }

  a {
    display: block;
    width: 100%;
    padding: 12px 0;
    color: #fff;
    background: $color-purple;
    text-align: center;
    font-size: 15px;
    border-radius: 0 15px 0 15px;
    box-shadow: 7px 7px 10px rgba(123, 89, 162, 0.3);
    border: 1px solid $color-purple;
    position: relative;

    @include pc {
      padding: 17px;
    }

    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 7px;
      background: url(../img/common/arrow_white.png) no-repeat center top/cover;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      background: #fff;
      color: $color-purple;

      &::after {
        background: url(../img/common/arrow_purple.png) no-repeat center top/cover;
      }
    }
  }

  &.white {
    a {
      background: #fff;
      color: $color-purple;
      border: 3px solid #fff;
      padding: 10px 0;

      @include pc {
        padding: 15px 0;
      }

      &::after {
        background: url(../img/common/arrow_purple.png) no-repeat center top/cover;
      }

      &:hover {
        border: 3px solid $color-purple;
      }
    }
  }
}

.c-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  @include pc {
    position: static;
  }

  .image-link {
    &::before {
      padding-top: 29.6%;
    }
  }

  h2 {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 15px;
    left: 50%;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background: url(../img/common/icon_search.png) no-repeat center top/cover;
      margin: 3px 14px 0 0;
    }

    &::after {
      content: '';
      display: block;
      width: 38px;
      height: 7px;
      background: url(../img/common/arrow_white_long.png) no-repeat center top/cover;
      margin-left: 10px;
    }
  }
}

.c-banner-faq {
  position: relative;

  .image-link {
    &::before {
      padding-top: 29.6%;

      @include pc {
        padding-top: 23%;
      }
    }
  }

  h2 {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 32px;
    width: 100%;
    font-family: $font-mont;

    span {
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      margin-left: 20px;
      font-family: $font-default;
    }
  }
}

.image-link {
  display: block;
  position: relative;
  text-decoration: none;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    /*ここには横幅に対して縦が何%になるかを記述*/
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.3s;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.sec-ttl {
  font-size: 32px;
  font-weight: 600;
  font-family: $font-mont;
  color: $color-purple;
  margin-bottom: 28px;

  @include pc {
    font-size: 52px;
    margin-bottom: 48px;
  }

  &.txt-center {
    text-align: center;
  }

  span {
    font-family: $font-default;
    color: $color-purple;
    font-size: 10px;
    display: block;

    @include pc {
      font-size: 14px;
    }
  }
}

.c-femnavi-wrapper {
  margin-bottom: 30px;

  @include pc {
    max-width: 1000px;
    margin: -350px auto 40px;
    display: flex;
    flex-wrap: wrap;
  }

  &.white {
    .content {
      border: none;
      padding-bottom: 0;
      margin-bottom: 30px;
      position: relative;

      @include pc {
        margin-bottom: 0;
        padding-bottom: 40px;
      }

      &::after {
        background: url(../img/common/arrow_white_long.png) no-repeat center top/cover;
      }

      a {
        .txt {

          .date,
          .category,
          .title {
            color: #fff;

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .content {
    position: relative;

    @include pc {
      margin-bottom: 30px;
      border: none;
      width: 24%;
      margin-right: 1%;
    }

    &:nth-child(4n) {
      @include pc {
        margin-right: 0;
      }
    }

    &:not(&:last-child) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e2deff;
      position: relative;

      @include pc {
        margin-bottom: 30px;
        padding-bottom: 0;
        border: none;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 38px;
      height: 7px;
      background: url(../img/common/arrow_purple_long.png) no-repeat center top/cover;
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: -1;

      @include pc {
        bottom: 10px;
      }
    }

    a {
      display: flex;
      position: relative;
      align-items: flex-start;
      position: relative;

      @include pc {
        padding-bottom: 30px;
        flex-direction: column;
      }
    }

    figure {
      position: relative;
      overflow: hidden;
      width: 120px;
      flex-shrink: 0;
      margin-right: 20px;

      @include pc {
        width: 100%;
        margin: 0 0 10px 0;
      }

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        inset: 0;
        position: absolute;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    .date {
      font-size: 12px;
      margin-bottom: 10px;
      color: $color-purple;

      @include pc {
        font-size: 15px;
      }
    }

    .category {
      margin-bottom: 10px;
      font-size: 14px;

      @include pc {
        font-size: 16px;
      }

      span {
        display: inline-block;
        margin-right: 4px;
      }
    }

    .title {
      font-size: 15px;
      font-weight: bold;
      text-decoration: underline;
      color: $color-purple;

      @include pc {
        font-size: 17px;
      }
    }
  }
}

.keyword-wrapper {
  display: flex;
  flex-wrap: wrap;

  .keyword-content {
    display: inline-flex;
    align-items: center;
    padding: 8px;
    background: #fff;
    font-size: 10px;
    margin: 0 10px 10px 0;

    @include pc {
      font-size: 15px;
      padding: 14px;
    }

    &::before {
      content: '#';
      color: $color-purple;
      display: block;
      margin: -3px 8px 0 0;
      font-size: 14px;
    }
  }
}

.swiper-button-next {
  right: 40px;

  &::after {
    background: url(../img/common/next.png) no-repeat center top/cover;
    width: 40px;
    height: 40px;
    font-size: 0;

    @include pc {
      width: 60px;
      height: 60px;
    }
  }
}

.swiper-button-prev {
  left: 40px;

  &::after {
    background: url(../img/common/prev.png) no-repeat center top/cover;
    width: 40px;
    height: 40px;
    font-size: 0;

    @include pc {
      width: 60px;
      height: 60px;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;

  @include pc {
    width: 60px;
    height: 60px;
  }
}

.news-content {
  &:first-child {
    a {
      border-top: 1px solid #e2deff;

      @include pc {
        border-top: none;
        border-bottom: 1px solid #e2deff;
      }
    }
  }

  a {
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid #e2deff;

    @include pc {
      display: flex;
    }

    .date-category {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;

      @include pc {
        font-size: 16px;
        margin: 0 40px 0 0;
      }

      .date {
        color: $color-purple;
        margin-right: 10px;

        @include pc {
          font-size: 15px;
          margin-right: 40px;
        }
      }
    }

    .title {
      color: $color-purple;
      text-decoration: underline;
      font-size: 15px;
      font-weight: bold;

      @include pc {
        font-size: 17px;
        text-decoration: none;
        line-height: 1.4;
        width: 56%;
      }
    }
  }
}

.sidenavi {
  background: rgb(152, 115, 186);
  background: linear-gradient(180deg, rgba(152, 115, 186, 1) 0%, rgba(150, 154, 218, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9873ba", endColorstr="#969ada", GradientType=1);
  padding: 90px 20px 70px;

  @include pc {
    padding: 250px 20px 140px;
  }

  .inner {
    @include pc {
      display: flex;
      max-width: 1000px;
      margin: 0 auto;
      justify-content: space-between;
    }
  }

  .navi {
    &:not(&:last-child) {
      margin-bottom: 30px;

      @include pc {
        margin-bottom: 0;
      }
    }

    @include pc {
      width: 31%;
    }

    h3 {
      color: #fff;
      font-size: 21px;
      font-family: $font-mont;
      font-weight: 600;
      padding-bottom: 10px;
      margin-bottom: 18px;
      border-bottom: 1px solid #fff;

      @include pc {
        font-size: 25px;
      }

      span {
        font-size: 11px;
        font-family: $font-default;
        display: inline-block;
        margin-left: 10px;
        color: #fff;

        @include pc {
          font-size: 13px;
          margin-left: 14px;
        }
      }
    }

    ul {
      li {
        a {
          color: #fff;
          display: flex;
          align-items: center;
          letter-spacing: 0.2em;

          &::before {
            content: '';
            display: block;
            width: 10px;
            height: 1px;
            background: #fff;
            margin-right: 10px;
          }
        }
      }
    }

    .keyword-wrapper {
      padding-bottom: 18px;
      margin-bottom: 40px;
      border-bottom: 1px solid #fff;
    }
  }

  &.purple {
    background: 0;
    padding: 0;

    .navi {
      @include pc {
        width: 100%;
      }

      h3 {
        color: $color-purple;
        border-bottom: 1px solid $color-purple;

        @include pc {
          font-size: 17px;
        }

        span {
          color: $color-purple;
        }
      }

      ul {
        li {
          a {
            color: $color-purple;

            &::before {
              background: $color-purple;
            }
          }
        }
      }
    }
  }
}

.search-word {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    width: calc(100% - 45px);
    padding: 9px 10px;
    font-size: 14px;
  }

  button {
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
  }
}

::-webkit-input-placeholder {
  color: #fff;
  font-size: 14px;
}

::-moz-placeholder {
  color: #fff;
  font-size: 14px;
}

:-ms-input-placeholder {
  color: #fff;
  font-size: 14px;
}

::-ms-input-placeholder {
  color: #fff;
  font-size: 14px;
}

::placeholder {
  color: #fff;
  font-size: 14px;
}

.breadcrumb-wrapper {
  @include pc {
    border-bottom: 1px solid #e2deff;
  }
}

.breadcrumb {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid #e2deff;

  @include pc {
    width: 1000px;
    margin: 0 auto;
    border: none;
  }

  span,
  a {
    font-size: 10px;
    font-weight: bold;
    color: $color-purple;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;

    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-bottom: 2px solid $color-purple;
      border-right: 2px solid $color-purple;
      transform: rotate(-45deg);
      margin: 5px 10px 0 0;
    }

    br {
      display: none !important;
    }
  }
}

.pagenation {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  .page-numbers {
    color: $color-default;
    font-family: $font-poppins;
    @include fz_vw(14);
    margin-right: 14px;

    @include pc {
      font-size: 14px;
    }

    &.current {
      color: $color-purple;
      border-bottom: 1px solid $color-purple;
    }

    &.prev {
      &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        transform: rotate(135deg);
        margin-right: 10px;
        border-bottom: 2px solid $color-purple;
        border-right: 2px solid $color-purple;
      }
    }

    &.next {
      &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        transform: rotate(-45deg);
        margin-left: 10px;
        border-bottom: 2px solid $color-purple;
        border-right: 2px solid $color-purple;
      }
    }

  }
}

.c-single-area {
  padding: 0 20px 20px;

  @include pc {
    padding: 0 20px 30px;
  }

  .wp-block-image .is-style-rounded img,
  .wp-block-image.is-style-circle-mask img,
  .wp-block-image.is-style-rounded img {
    border-radius: 20px;
  }

  #ez-toc-container {
    padding: 10px;
    background: rgba(152, 115, 186, 0.1);
    border-radius: inherit;
    border: none;
    width: 100%;

    @include pc {
      padding: 30px;
      margin-bottom: 40px;
    }

    a {
      &:visited {
        color: $color-default;
      }
    }

    .ez-toc-title-container {
      margin-bottom: 10px !important;
    }
  }

  .ez-toc-list {
    li {
      display: block !important;

      &::before {
        counter-increment: number;
        content: ''counter(number) ':';
      }

      a {
        margin-bottom: 10px !important;
        color: $color-default;

        &::before {
          display: none;
        }
      }
    }
  }

  .title-area {
    padding-bottom: 10px;
    margin-bottom: 18px;
    border-bottom: 1px solid #e2deff;

    @include pc {
      margin-bottom: 50px;
    }
  }

  .date-category {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;

    .date {
      font-size: 14px;
      color: $color-purple;
      margin-right: 10px;
    }

    .category {
      font-size: 14px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: $color-purple;
    line-height: 1.5;

    @include pc {
      font-size: 25px;
    }
  }

  .thmubnail {
    margin-bottom: 40px;

    @include pc {
      width: 60%;
    }
  }

  .content-area {
    .wp-block-image {
      margin-bottom: 40px !important;

      figcaption {
        margin-bottom: 0;
      }

      img {
        width: 100%;

        @include pc {
          width: auto;
        }
      }
    }

    p {
      font-size: 14px;
      line-height: 1.78;
      margin-bottom: 24px;

      @include pc {
        font-size: 16px;
      }
    }

    strong {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 24px;
      display: block;

      @include pc {
        font-size: 16px;
      }
    }

    figcaption {
      a {
        margin: 0;
      }
    }

    a {
      display: inline-block;
      margin-bottom: 24px;
      color: $color-purple;
      text-decoration: underline;

      @include pc {
        font-size: 16px;
      }
    }

    h2 {
      line-height: 1.5;
      margin-bottom: 18px;
      padding-bottom: 15px;
      border-bottom: 4px double $color-purple;

      @include pc {
        font-size: 20px;
      }
    }

    h3 {
      border-bottom: 3px solid #e9e6ff;
      margin-bottom: 18px;
      padding-bottom: 10px;

      @include pc {
        font-size: 20px;
      }
    }

    h4 {
      margin-bottom: 18px;
      padding: 14px 20px;
      background: rgb(150, 154, 218, 0.2);
      background: linear-gradient(90deg, rgba(150, 154, 218, 0.2) 0%, rgba(152, 115, 186, 0.2) 100%);
      border-radius: 12px;

      @include pc {
        font-size: 20px;
      }
    }

    h5 {
      border-top: 1px solid $color-default;
      border-bottom: 1px solid $color-default;
      padding: 10px 0;
      font-size: 14px;
      margin-bottom: 18px;

      @include pc {
        font-size: 17px;
      }
    }

    ul,
    ol {
      background: rgba(152, 115, 186, 0.1);
      padding: 20px;
      margin-bottom: 24px;
      list-style: none;
      counter-reset: number;

      li {
        font-size: 15px;
        display: flex;

        @include pc {
          font-size: 16px;
        }

        &:not(&:last-child) {
          margin-bottom: 10px;
        }

        &::before {
          counter-increment: number;
          content: ''counter(number) ':';
        }
      }
    }
  }

  .keyword-wrapper {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2deff;

    .keyword-content {
      background: rgb(150, 154, 218, 0.2);
      background: linear-gradient(90deg, rgba(150, 154, 218, 0.2) 0%, rgba(152, 115, 186, 0.2) 100%);
    }
  }
}

.head-area {
  display: flex;
  flex-direction: column;

  @include pc {
    flex-direction: column-reverse;
    border-bottom: 1px solid #e2deff;
  }

  h2 {
    padding: 20px 0;
    background: #e9e6ff;

    @include pc {
      padding: 150px 0 80px;
      margin-bottom: 0;
    }
  }
}

.prev-next {
  display: flex;
  align-items: center;
  justify-content: center;

  @include pc {
    width: 100%;
  }

  .c-btn {
    margin: 0 auto;
  }

  .prev {
    @include pc {
      display: flex;
      align-items: center;
    }

    span {
      width: 15px;
      height: 7px;
      background: url(../img/common/arrow_purple.png) no-repeat center top/cover;
      transform: scale(-1, 1);
      display: block;
      margin-right: 10px;
    }

    &::after {
      @include pc {
        content: '前の記事';
        display: block;
      }
    }
  }

  .next {
    @include pc {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }

    span {
      width: 15px;
      height: 7px;
      background: url(../img/common/arrow_purple.png) no-repeat center top/cover;
      display: block;
      margin-left: 10px;
    }

    &::after {
      @include pc {
        content: '次の記事';
        display: block;
      }
    }
  }
}

.links-wrapper {
  .links {
    background: #f3f5ff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 20px;
    margin-bottom: 40px;

    @include pc {
      max-width: 1000px;
      margin: 50px auto;
      padding: 50px;
    }

    a {
      background: #fff;
      color: $color-purple;
      width: 48%;
      text-align: center;
      font-size: 10px;
      padding: 16px 0;
      box-shadow: 5px 5px 5px rgba(123, 89, 162, 0.2);
      position: relative;

      @include pc {
        width: 24%;
        font-size: 12px;
      }

      &:nth-child(n + 3) {
        margin-top: 10px;

        @include pc {
          margin-top: 0;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-bottom: 2px solid $color-purple;
        border-right: 2px solid $color-purple;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        right: 14px;
      }
    }
  }
}

.under-arrow {
  text-align: center;
  font-size: 18px;
  line-height: calc(30 / 18);

  @include pc {
    font-size: 31px;
  }

  span {
    display: inline-block;
    border-bottom: 1px solid $color-purple;
    padding-bottom: 14px;
    margin-bottom: 20px;
    color: $color-purple;
    position: relative;
    font-weight: bold;

    @include pc {
      padding: 0 20px 24px;
      margin-bottom: 30px;
    }

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-bottom: 1px solid $color-purple;
      border-right: 1px solid $color-purple;
      position: absolute;
      transform: translateX(-50%) rotate(45deg);
      left: 50%;
      bottom: -6px;
      background: #fff;

      @include pc {
        width: 20px;
        height: 20px;
        bottom: -11px;
      }
    }
  }
}

.storker-ec {
  position: fixed;
  z-index: 800;
  width: 103px;
  right: 15px;
  bottom: 5%;

  @include pc {
    width: 255px;
    right: 100px;
    bottom: 10%;
  }
}