@import "../Foundation/_mediaqueries";
@import "../Foundation/fontsize";

.l-footer {
  position: relative;

  .to-top {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    color: $color-purple;
    font-size: 10px;
    position: absolute;
    right: 20px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      display: block;
      height: 60px;
      width: 1px;
      background: $color-purple;
      margin-bottom: 10px;
    }
  }

  .nav {
    padding: 70px 20px 50px;
    background: #e2deff;
    border-bottom: 3px solid $color-purple;

    @include pc {
      padding: 80px 0 40px;
    }

    .nav-inner {
      @include pc {
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        max-width: 1100px;
      }
    }

    .logo-sns {
      @include pc {
        margin-right: 120px;
      }
    }

    .logo {
      width: 220px;
      margin-bottom: 10px;
    }

    .sns {
      display: flex;
      margin-bottom: 24px;

      a {
        margin-right: 14px;

        img {
          height: 20px;
          width: auto;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }

    ul {
      @include pc {
        margin-right: 70px;
      }

      &:last-child {
        li {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      li {
        margin-bottom: 1em;

        a {
          font-size: 14px;
          color: $color-purple;
          font-weight: 500;
        }
      }
    }
  }

  .other-nav {
    margin-top: 16px;

    @include pc {
      max-width: 1200px;
      margin: 0 auto;
      padding: 18px 0;
    }

    ul {
      @include pc {
        display: flex;
        align-items: center;
      }

      li {
        padding: 0 20px;
        padding-bottom: 10px;
        margin-bottom: 16px;
        border-bottom: 1px solid #e5e9e9;

        @include pc {
          border-bottom: none;
          border-right: 1px solid $color-default;
          margin-bottom: 0;
          padding: 0;
          line-height: 1;
          margin-right: 20px;
          padding-right: 20px;
        }

        &:nth-child(3) {
          margin-right: 0;
          border: none;
        }

        &:last-child {
          font-size: 10px;
          padding-bottom: 0;
          border: none;

          @include pc {
            margin-left: auto;
          }
        }

        a {
          font-size: 12px;
        }
      }
    }
  }
}