@import "../Foundation/_mediaqueries";

body.is-drawerActive {
  overflow-y: hidden;
}

body.is-drawerActive .l-header .nav-wrap {
  opacity: 1;
  visibility: visible;
}

body.is-drawerActive .l-header .inner .logo-wrap {
  opacity: 0;
}

body.is-drawerActive {
  .l-header {
    &.is-top {
      .humburger-wrap {
        .hamburger {
          &::before {
            content: 'CLOSE';
            display: block;
            font-size: 10px;
            color: $color-purple;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            bottom: 0;
          }
        }
      }
    }
  }
}

.l-header {
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;

  // @include pc {
  //   background: #e9e6ff;
  // }

  .inner {
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include pc() {
      padding: 33px 70px;
    }
  }

  .logo-wrap {
    position: relative;
    z-index: 9999;
    flex-shrink: 0;
    margin-right: 20px;

    img {
      width: auto;
      height: 31px;

      @include pc {
        height: 45px;
      }
    }
  }

  .nav_wrapper {
    @include pc {
      display: flex;
      align-items: center;
    }
  }

  .nav_pc {
    @include pc {
      ul {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 14px 30px;
        border-radius: 30px;
        margin-right: 20px;

        li {
          &:not(&:last-child) {
            margin-right: 40px;
          }

          a {
            color: $color-purple;
            font-size: clamp(1vw, 1.2vw, 16px);

            &.current {
              position: relative;

              &::before {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background: $color-default;
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                bottom: -27px;
              }
            }

            &.cart {
              display: block;
              width: 28px;
              height: 28px;
              background: url(../img/common/icon_cart_black.png) no-repeat center top/cover;
            }
          }
        }
      }
    }
  }

  .humburger-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include pc() {
      display: block;
      height: 60px;
    }

    .button-wrap {
      position: relative;
      display: flex;
      align-items: center;
    }

    .button {
      position: relative;
      display: inline-block;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      z-index: 100;
    }

    .hamburger {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 0 0 2rem transparent;
      outline: none;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;
      border-radius: 50%;
      background: $color-purple;

      @include pc() {
        margin-bottom: -8px;
        width: 60px;
        height: 60px;
      }
    }

    .hamburger-line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 36px;
      height: 2px;
      background-color: transparent;
      transition: inherit;
    }

    .hamburger-line::before,
    .hamburger-line::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      content: "";
      transition: inherit;
      left: 50%;
      transform: translateX(-50%);
    }

    .hamburger-line::before {
      width: 22px;
      top: 3px;
    }

    .hamburger-line::after {
      width: 22px;
      top: -3px;
    }

    .hamburger[aria-expanded="true"] .hamburger-line {
      width: 18px;
      background-color: transparent;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before,
    .hamburger[aria-expanded="true"] .hamburger-line::after {
      width: 31px;
      top: 0px;
      right: -6px;
      left: initial;
      background-color: #fff;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before {
      transform: rotate(35deg);
    }

    .hamburger[aria-expanded="true"] .hamburger-line::after {
      transform: rotate(-35deg);
    }

    .visuallyHidden {
      position: absolute;
      white-space: nowrap;
      width: 1px;
      height: 1px;
      overflow: hidden;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      margin: -1px;
    }

  }

  .nav-wrap {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 3;

    @include pc() {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }

    .nav_sp {
      width: 90%;
      margin-left: auto;
      padding: 70px 0 70px 36px;
      background-color: #fff;
      border-radius: 30px 0 0 30px;

      @include pc {
        width: 34%;
      }

      ul {
        margin-bottom: 30px;

        li {
          margin-bottom: 20px;

          a {
            color: $color-purple;
            font-weight: 500;
          }
        }

        &.small {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0;

          li {
            margin: 0 20px 6px 0;

            a {
              font-size: 10px;
              color: $color-default;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }


  &.is-top,
  &.is-content {
    background-color: transparent;
    border-bottom: none;

    .button-wrap {
      position: relative;
      flex-shrink: 0;
      border-radius: 50%;

      @include pc {
        height: 60px;
        width: 60px;
        background: #fff;
      }

      &::before {
        color: $color-purple;
      }
    }


    .humburger-wrap {
      .hamburger {
        width: 36px;
        height: 36px;
        border: none;
        background: none;

        @include pc {
          border-radius: 50%;
          background: #fff;
          width: 60px;
          height: 60px;
          display: block;
          margin-bottom: 0;
        }

        &::before {
          content: 'MENU';
          display: block;
          font-size: 10px;
          color: $color-purple;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          bottom: 0;

          @include pc {
            display: none;
          }
        }
      }
    }

    .hamburger-line::before {
      width: 36px;
      top: -10px;

      @include pc {
        top: -3px;
        width: 22px;
      }
    }

    .hamburger-line::after {
      width: 36px;
      top: -4px;

      @include pc {
        top: 4px;
        width: 22px;
      }
    }

    .hamburger-line::before,
    .hamburger-line::after {
      background-color: $color-purple;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before,
    .hamburger[aria-expanded="true"] .hamburger-line::after {
      width: 31px;
      top: 0px;
      right: -6px;
      left: initial;
      background-color: $color-purple;
    }

    .logo-wrap {
      opacity: 0;

      @include pc {
        opacity: 1;
      }
    }
  }

  &.is-content {
    .inner {
      padding: 8px 20px;

      @include pc {
        padding: 30px 60px;
      }
    }

    .logo-wrap {
      opacity: 1;
    }

    .humburger-wrap {
      .hamburger {
        &::before {
          display: none;
        }

        @include pc {
          background: $color-purple;
        }
      }

      .hamburger[aria-expanded="true"] .hamburger-line::before,
      .hamburger[aria-expanded="true"] .hamburger-line::after {
        background: #fff;
      }
    }


    .hamburger-line::before,
    .hamburger-line::after {
      background-color: #fff;
    }

    .hamburger-line::before {
      width: 36px;
      top: -2px;
    }

    .hamburger-line::after {
      width: 36px;
      top: 5px;
    }

  }
}

.post-type-archive-content,
.single-content {
  .l-header {
    .inner {
      background: $color-purple;
    }
  }
}